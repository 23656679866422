import React from 'react';
import axios from 'axios';
import CryptoJS, {AES} from 'crypto-js';

const BASE_URL= process.env.REACT_APP_BASE_URL;
let secretKey = '';
let random = 123;

export const encryptData = (data: string, secretKey: string): string => {
    let iv = CryptoJS.enc.Hex.parse("0000000000000000");
    return CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.ECB,
    }).toString();
};

export const decryptData = (encryptedData: string, secretKey: string): string => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(secretKey), {
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.ECB
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};

const source = axios.CancelToken.source();
const postMessage = async (type: string, content: string): Promise<string> => {
    //检查是否存在密钥，
    const existSecretKey = secretKey.length === 32
    if (existSecretKey) {
        content = encryptData(content, secretKey)
    }

    const requsetInfo = {
        encrypted: existSecretKey,
        action: type,
        data: content,
        random: random
    }
    
    const user = localStorage.getItem('user');
    
    const token = user ? JSON.parse(user).token : null;
    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    try {
        const response = await axios.post(`${BASE_URL}/post_message`, 
                            requsetInfo, 
                            {
                                headers: { 'Content-Type': 'application/json', ...headers, },
                                cancelToken:source.token
                            });
        
        if (!existSecretKey) {
            secretKey = response.data.key;
        }
        //解密后返回
        const message = decryptData(response.data.message, secretKey);
        if (response.data.state == false) {
            throw new Error(message)
        } else {
            return message;
        }

    } catch (error) {
        if (axios.isCancel(error)) {
            console.error('Request canceled', error.message);
        } else {
            console.error('There has been a problem with axios operation', error)
            throw error;
        }
    }

    return "";
}


export {postMessage};
