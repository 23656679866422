import React,  { useEffect, useRef, useState }  from "react";
import {SubmitHandler, useForm } from "react-hook-form";
import { postMessage } from '../../internet/connectIntf';
import { useData } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import "./Register.css"
interface registerFrom {
  phone: string,
  name: string,
  password: string,
  pswConfirm: string,
  code: string
}

const Regiter: React.FC = () => {
  const [pswV, setPswV] = useState(false);
  const [pswC, setPswC] = useState(false);
  const [counting, setCounting] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const timerRef = useRef(0)

  let navigate = useNavigate();
  const { login } = useData();

  const {register, handleSubmit:registSubmit, watch, setError:setRegistError, clearErrors: clearRegistError, formState:{errors: registError} } = useForm<registerFrom>();
  const watchPhone = watch('phone');
  const watchPassword = watch('password');
  const handileRegister:SubmitHandler<registerFrom> = async (data) => {
    try {
      const response = await postMessage('register', JSON.stringify(data));
      login(JSON.parse(response));
      navigate('/');
    } catch (error) {
      if (error instanceof Error) {
        if (error.message == "昵称已存在") {
          setRegistError("name", {
            message: "用户名已存在"
          })
        } else {
          setRegistError("root", {
            message:error.message
          });
        }
      }
    }
  }

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (isWaiting && counting > 0) {
        setCounting(preCount => preCount - 1)
      } else {
        setIsWaiting(false)
        setCounting(0)
      }
    }, 1000)

    return () => {
      clearInterval(timerRef.current)
    }

  }, [counting]);

  const startWaiting = () => {
    setCounting(60)
    setIsWaiting(true)
  }

  const register_auth = async () => {
    clearRegistError()
    if ( watchPhone.length == 11) {
      const info = {
        phone: watchPhone,
      }
      try {
        startWaiting()
        const response = await postMessage('register_auth', JSON.stringify(info));
        console.log(response)
      } catch (error) {
        if (error instanceof Error) {
          setRegistError("root", {
            message: error.message
          })
          console.log(error.message)
        }
          
      }
    } else {
      setRegistError("phone", {
        message: "请输入合法手机号"
      })
    }
  }

  return (
    <form className='form regiter-form' onSubmit={registSubmit(handileRegister)}>
      <p>江口水镇用户注册</p>
      <div>
        <label className='label'>+86</label>
        <input {...register("phone", {
          required: "请输入电话",
          pattern: {
            value: /^\d{11}$/,
            message: "请输入正确电话格式"
          }
        })} className='tel' placeholder='手机号'/>
      </div>
      {registError.phone && <span className= 'warning'>{registError.phone.message}</span>}
      <div className='regiest_code'>
        <input {...register("code", {
          required:"请输入验证码",
          pattern: {
            value: /^\d{6}$/,
            message: "请输入六位验证码"
          }
        })} maxLength={6} className='input_code'></input>
        <button  className="code-btn" type='button' disabled={isWaiting} onClick={register_auth}>{ isWaiting ? counting : '发送验证码' }</button>
      </div>
      <input {...register("name", {
        required: "请输入用户名",
        minLength: {
          value: 2,
          message: "至少包含2个字符",
        },
        maxLength: {
          value: 18,
          message: "最多包含18个字符"
        }
      })} placeholder='用户名' />
      {registError.name && <span className='warning'>{registError.name.message}</span>}
      <div>
        <input {...register("password", {
          required: "请输入密码"
        })} id='password' type= {pswV ? 'text' : 'password'} placeholder='密码' />
        <span className='visible_icon' onClick={()=>setPswV(!pswV)} ><i className={`bi ${pswV ? 'bi-eye' : 'bi-eye-slash'}`} /></span>
      </div>
      {registError.password && <span className='warning'>{registError.password.message}</span>}
      <div>
        <input {...register("pswConfirm", {
          required: "请再次输入密码",
          validate: value =>
            value === watchPassword || '两次密码输入不一致'
        })} type={pswC ? 'text' : 'password'} placeholder='确认密码' />
        <span className='visible_icon' onClick={()=>setPswC(!pswC)} ><i className={`bi ${pswC ? 'bi-eye' : 'bi-eye-slash'}`} /></span>
      </div>
      {registError.pswConfirm && <span className='warning'>{registError.pswConfirm.message}</span>}
      <button type='submit' >注册</button>
      {registError.root && <span className='warning'>{registError.root.message}</span>}
    </form>
  );
}

export default Regiter;