import React, { useState } from 'react'
import './Tabbar.css'

interface Tab {
  title: string;
}

interface TabbarProps {
  tabs?: Tab[];
  onTabChange?: (index: number) => void;
}

const Tabbar: React.FC<TabbarProps> = ({tabs = [], onTabChange = () => {}}) => {
  const [activeTabe, setActiveTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    onTabChange(index);
  }

  return (
    <div className='tab-header'>
      {tabs.map((tab, index) => (
        <div className={`tab ${activeTabe === index ? 'active' : ''}`}
          onClick={() => handleTabClick(index)}
          >{tab.title}</div>
      ))}
    </div>
  )
};

export default Tabbar;
