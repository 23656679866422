export const buildings = [
    // { position: [, ], unit: '栋' },
    { position: [103.92272550064934, 30.24246147841523], unit: '1栋' },
    { position: [103.92238584184571, 30.241993049563774], unit: '2栋' },
    { position: [103.92215660364502, 30.241531192556504], unit: '3栋' },
    { position: [103.92195486294793, 30.241034686756073], unit: '4栋' },
    { position: [103.92267679713683, 30.24081549522737], unit: '5栋' }, 
    { position: [103.92335329975452, 30.240535640901825], unit: '6栋' },
    { position: [103.92337916428164, 30.241204490652215], unit: '7栋' },
    { position: [103.92370141936522, 30.241661883023102], unit: '8栋' },
    { position: [103.92342961347589, 30.242253766925526], unit: '9栋' },
    { position: [103.92415827722385, 30.241978907106876], unit: '业主中心' },
    { position: [103.92451489874355, 30.2415414253361], unit: '10栋' },
    { position: [103.92417867635015, 30.24094794170553], unit: '11栋' },
    { position: [103.92394763594541, 30.24032424429516], unit: '12栋' },
    { position: [103.92459314913606, 30.240318202008034], unit: '13栋' },
    { position: [103.92524493151063, 30.24042183647017], unit: '14栋' },
    { position: [103.92574883478619, 30.24082290250437], unit: '15栋' },
    { position: [103.92513434280136, 30.241119202059263], unit: '16栋' },
    { position: [103.92322183305271, 30.243274842849047], unit: '17栋' },
    { position: [103.92375361237083, 30.242838109903815], unit: '18栋' },
    { position: [103.92441000257071, 30.242513482963325], unit: '19栋' },
    { position: [103.92509928646291, 30.242999895098443], unit: '20栋' },
    { position: [103.92471717619298, 30.243264001048953], unit: '21栋' },
    { position: [103.92435698336618, 30.243531228970518], unit: '22栋' },
    { position: [103.92399698336612, 30.243798228244447], unit: '23栋' },
    { position: [103.92347752963218, 30.24366489042632], unit: '24栋' },
    { position: [103.92552218087371, 30.242625283710364], unit: '25栋' },
    { position: [103.9250815733534, 30.242007289864507], unit: '26栋' },
    { position: [103.9256835707269, 30.241663401669616], unit: '27栋' },
    { position: [103.92622889745951, 30.24120237080191], unit: '28栋' },
    { position: [103.92630949330561, 30.242065624271895], unit: '29栋' },
    { position: [103.92591266074379, 30.24237088899878], unit: '30栋' },
    { position: [103.92159927947915, 30.24143972630993], unit: '西门' },
    { position: [103.92559909120882, 30.243167550118923], unit: '北门' },
    { position: [103.9230483900958, 30.24190301124221], unit: '篮球场' },
    { position: [103.92544988464437, 30.24126403103383], unit: '乒乓球场' },
    { position: [103.9240374421521, 30.243223107201306], unit: '足球场' }, 


    // 拼叠 { position: [], unit: '' }, 
    { position: [103.92181301033588, 30.238712663762932], unit: '1-1、2' }, 
    { position: [103.92206721501634, 30.23877215497897], unit: '1-3' }, 
    { position: [103.92165944894208, 30.23901243248586], unit: '2' }, 
    { position: [103.92203426978608, 30.23908742327036], unit: '3' }, 
    { position: [103.92189544423167, 30.239318685189467], unit: '4' }, 
    { position: [103.92154781626772, 30.23924902524387], unit: '5' }, 
    { position: [103.92142353790729, 30.23949747795228], unit: '6' }, 
    { position: [103.92174498118652, 30.239570511625278], unit: '7' }, 
    { position: [103.92161180372534, 30.239814171583117], unit: '8' }, 
    { position: [103.92128665172254, 30.239741785471306], unit: '9' }, 
    { position: [103.92117127208923, 30.240015353817252], unit: '10' }, 
    { position: [103.92155312046009, 30.240088511237825], unit: '11' }, 
    { position: [103.92160758863719, 30.2403374758287], unit: '12' }, 
    { position: [103.92124334790378, 30.24030487208108], unit: '13' }, 
    { position: [103.92134562663784, 30.24054062414467], unit: '14' }, 
    { position: [103.92168462076762, 30.240635254854634], unit: '15' }, 
    { position: [103.92192428902774, 30.24055752701177], unit: '16' }, 
    { position: [103.92183082218821, 30.24026392541643], unit: '17' }, 
    { position: [103.92188528317922, 30.239986751385814], unit: '18' }, 
    { position: [103.92199850119295, 30.23972396170895], unit: '19' }, 
    { position: [103.92217829898595, 30.239475172145575], unit: '20' }, 
    { position: [103.92243245166145, 30.239288700667124], unit: '21' }, 
    { position: [103.92272644512377, 30.239153860597142], unit: '22' }, 
    { position: [103.92303031479264, 30.239072862977228], unit: '23' }, 
    { position: [103.9233145754547, 30.23896068706496], unit: '24' }, 
    { position: [103.92335283373097, 30.238701955877623], unit: '25' }, 
    { position: [103.9230355256488, 30.238785801936285], unit: '26' }, 
    { position: [103.92271523575914, 30.238805496041685], unit: '27' }, 
    { position: [103.9223909458695, 30.239036509649086], unit: '28' }, 
    { position: [103.92240580450346, 30.238776801414666], unit: '29' }, 
    { position: [103.92212781779278, 30.238270731397968], unit: '30' }, 
    { position: [103.92244006161951, 30.238404908812814], unit: '31' }, 
    { position: [103.92226278100304, 30.23810399392182], unit: '32' }, 
    { position: [103.92253859697627, 30.23824587824408], unit: '33' }, 
    { position: [103.92263442245388, 30.238061283322075], unit: '34' }, 
    { position: [103.92244825702574, 30.237885412413682], unit: '35' }, 
    { position: [103.92263814223656, 30.237672481555936], unit: '36' }, 
    { position: [103.92287738715459, 30.237850991463574], unit: '37' }, 
    { position: [103.92310664956983, 30.237654002402756], unit: '38' }, 
    { position: [103.92283408523275, 30.237452921574423], unit: '39' }, 
    { position: [103.92305097633573, 30.23725506192007], unit: '40' }, 
    { position: [103.92331184482669, 30.237472661886564], unit: '41' }, 
    { position: [103.92352770631163, 30.237250981437008], unit: '42' }, 
    { position: [103.92325598053935, 30.237044978092335], unit: '43' }, 
    { position: [103.92347893844823, 30.2368196584256], unit: '44' }, 
    { position: [103.92373448040865, 30.23698737033694], unit: '45' }, 
    { position: [103.92394522395989, 30.237195393323248], unit: '46' }, 
    { position: [103.9237356412371, 30.23745390171586], unit: '47' }, 
    { position: [103.92394477968617, 30.237656126917535], unit: '48' }, 
    { position: [103.92415141210802, 30.237394305963104], unit: '49' }, 
    { position: [103.92435137231928, 30.23760194273046], unit: '50' }, 
    { position: [103.92415628493575, 30.23785986127152], unit: '51' }, 
    { position: [103.92398001889751, 30.23802945653844], unit: '52' }, 
    { position: [103.92369298098879, 30.237909408343075], unit: '53' }, 
    { position: [103.9235340827513, 30.237662237083374], unit: '54' }, 
    { position: [103.92333233055535, 30.237865981438574], unit: '55' }, 
    { position: [103.92287033691673, 30.23823298201578], unit: '56' }, 
    { position: [103.92276651584223, 30.238430061264484], unit: '57' }, 
    { position: [103.92309215115671, 30.238413955036464], unit: '58' }, 
    { position: [103.92322281239365, 30.238196118998513], unit: '59' }, 
    { position: [103.9234048050173, 30.238392431517692], unit: '60' }, 
    { position: [103.92356740074115, 30.238199696690103], unit: '61' }, 
    { position: [103.92388429951956, 30.238268992980085], unit: '62' }, 
    { position: [103.92372680660054, 30.2384209807485], unit: '63' }, 
    { position: [103.92412799706517, 30.238569496151484], unit: '64' }, 
    { position: [103.92419821851001, 30.238373784811873], unit: '65' }, 
    { position: [103.92442313619716, 30.238031209655095], unit: '66' }, 
    { position: [103.9246349456024, 30.237823811384786], unit: '67' }, 
    { position: [103.92478027656495, 30.237967103087506], unit: '68' }, 
    { position: [103.924604138895, 30.238202538191498], unit: '69' }, 
    { position: [103.9244230414717, 30.23847381431983], unit: '70' }, 
    { position: [103.92435758306732, 30.238760055155765], unit: '71' }, 
    { position: [103.92464601310951, 30.23867464889229], unit: '72' },
    { position: [103.92481506454055, 30.238380653132076], unit: '73' },
    { position: [103.92497955026249, 30.238160464520167], unit: '74' },
    { position: [103.92520579071578, 30.238373573260738], unit: '75' },
    { position: [103.92502767631777, 30.238600571538388], unit: '76' },
    { position: [103.92488948635355, 30.238863991223898], unit: '77' },
    { position: [103.92460585592283, 30.238955377916323], unit: '78' },
    { position: [103.9248548846515, 30.23909338610265], unit: '79' }, 
    { position: [103.92512605251517, 30.239042264799043], unit: '80' }, 
    { position: [103.92523784694868, 30.238799749255058], unit: '81' }, 
    { position: [103.9254125067514, 30.23857146871664], unit: '82' }, 
    { position: [103.92560987188693, 30.23876931197569], unit: '83' }, 
    { position: [103.92603319143643, 30.23870619941523], unit: '84' }, 
    { position: [103.92581854175091, 30.238900449663824], unit: '85' }, 
    { position: [103.92573786317729, 30.239173960015076], unit: '86' }, 
    { position: [103.92545191805596, 30.238998203716317], unit: '87' }, 
    { position: [103.92512037835866, 30.239221094455708], unit: '88' }, 
    { position: [103.9254346405121, 30.23931026682782], unit: '89' }, 
    { position: [103.92527713538323, 30.239640113140247], unit: '90' }, 
    { position: [103.92526720150653, 30.239920026545207], unit: '91' }, 
    { position: [103.92496460830085, 30.239845606545085], unit: '92' },
    { position: [103.92495737894956, 30.239543987273997], unit: '93' },
    { position: [103.92467502113283, 30.23938683544349], unit: '94' },
    { position: [103.9246564917346, 30.239803586930208], unit: '95' },
    { position: [103.92441103766362, 30.23923862529923], unit: '96' },
    { position: [103.9244077338355, 30.23950321424236], unit: '97' },
    { position: [103.92414880357529, 30.23934172888478], unit: '98' },
    { position: [103.9239176054673, 30.239158558993864], unit: '99' }, 
    { position: [103.9242084764386, 30.239042880769308], unit: '100' }, 
    { position: [103.92397145934956, 30.23885376403424], unit: '101' }, 
    { position: [103.92374158274924, 30.23902220370028], unit: '102' }, 
    { position: [103.9234976473727, 30.23919430554998], unit: '103' }, 
    { position: [103.9236703319292, 30.239350974928865], unit: '104' }, 
    { position: [103.92391877488615, 30.23953853320705], unit: '105' }, 
    { position: [103.92432468411351, 30.239773601563822], unit: '106' }, 
    { position: [103.92401453430648, 30.23980931962507], unit: '107' }, 
    { position: [103.92370185209757, 30.23986097467898], unit: '108' }, 
    { position: [103.92361415765475, 30.239659962296084], unit: '109' }, 
    { position: [103.92338265947245, 30.239487203193843], unit: '110' }, 
    { position: [103.92321737359333, 30.239312609787653], unit: '111' }, 
    { position: [103.9229051932021, 30.239404070065138], unit: '112' }, 
    { position: [103.92308403745176, 30.23958179348628], unit: '113' }, 
    { position: [103.92332950749687, 30.239744731175637], unit: '114' }, 
    { position: [103.92339236135967, 30.239957551238252], unit: '115' }, 
    { position: [103.92310701529215, 30.24007551807744], unit: '116' }, 
    { position: [103.92303793311783, 30.23986449901109], unit: '117' }, 
    { position: [103.92277044965078, 30.23968493978872], unit: '118' }, 
    { position: [103.92260900586723, 30.23952216010507], unit: '119' }, 
    { position: [103.92237564723871, 30.239727222413457], unit: '120' }, 
    { position: [103.92253626198868, 30.23989903604488], unit: '121' }, 
    { position: [103.92276367055229, 30.240012242491595], unit: '122' }, 
    { position: [103.92286793559936, 30.240255581133336], unit: '123' }, 
    { position: [103.9226088701871, 30.240415724923665], unit: '124' }, 
    { position: [103.92241923371228, 30.240166265632368], unit: '125' }, 
    { position: [103.92223862144368, 30.239963080175457], unit: '126' }, 
    { position: [103.92215716392008, 30.240240001005446], unit: '127' }, 
    { position: [103.92224349981637, 30.24046686491771], unit: '128' }, 
    { position: [103.92598937572018, 30.239827195851564], unit: '129' }, 
    { position: [103.92590255785893, 30.240059840973142], unit: '130' }, 
    { position: [103.9262760148797, 30.24008415450654], unit: '131' }, 
    { position: [103.9259566056719, 30.24028911259978], unit: '132' }, 
    { position: [103.92618379305594, 30.240426565545505], unit: '133' },
    { position: [103.92648200452749, 30.24022837309582], unit: '134' },
    { position: [103.92667628347112, 30.240394445403105], unit: '135' },
    { position: [103.92638883200078, 30.240581843696056], unit: '136' },
    { position: [103.92657258898168, 30.240744309868408], unit: '137' },
    { position: [103.92684713927838, 30.240557131019507], unit: '138' },
    { position: [103.92702735079189, 30.240736860727115], unit: '139' },
    { position: [103.92674757237813, 30.24093341079767], unit: '140' },
    { position: [103.92691539989937, 30.241115468349587], unit: '141' }, 
    { position: [103.92720400802136, 30.240914507955196], unit: '142' }, 
    { position: [103.92741120064642, 30.24106866019112], unit: '143' }, 
    { position: [103.9271219919786, 30.241275176094113], unit: '144' }, 
    { position: [103.92732383622837, 30.241430197507135], unit: '145' }, 
    { position: [103.92760093808073, 30.241237618777177], unit: '146' }, 
    { position: [103.92814467170882, 30.240983175730882], unit: '147' }, 
    { position: [103.92781948655806, 30.241095694077288], unit: '148' }, 
    { position: [103.92768411378319, 30.240880456063827], unit: '149' }, 
    { position: [103.92801873546688, 30.24076353799392], unit: '150' }, 
    { position: [103.92783520954339, 30.240587435482368], unit: '151' }, 
    { position: [103.9274882951986, 30.240723660946347], unit: '152' }, 
    { position: [103.92728750487618, 30.24056869275848], unit: '153' }, 
    { position: [103.92763906601022, 30.24042675175581], unit: '154' }, 
    { position: [103.92743512263235, 30.240273325870103], unit: '155' }, 
    { position: [103.92709207941397, 30.240403406668182], unit: '156' }, 
    { position: [103.92689750822416, 30.240232283364577], unit: '157' }, 
    { position: [103.92724498120816, 30.240117611269362], unit: '158' }, 
    { position: [103.92706389236986, 30.23994777874489], unit: '159' }, 
    { position: [103.92672144146013, 30.240070120100516], unit: '160' }, 
    { position: [103.92689628317163, 30.239766308761702], unit: '161' }, 
    { position: [103.92666700861798, 30.239609085733438], unit: '162' }, 
    { position: [103.92630923317853, 30.239841006007975], unit: '163-1' }, 
    { position: [103.92651448891456, 30.239911023949404], unit: '163-2' }, 
    { position: [103.9264009036782, 30.2395173282566], unit: '164' }, 
    { position: [103.92604697605191, 30.23947162857948], unit: '165-3' }, 
    { position: [103.92603818339342, 30.239288485539646], unit: '165-1、2' }, 
    { position: [103.92609499694163, 30.239041159323527], unit: '166' }, 
    { position: [103.92639901758025, 30.239292886834928], unit: '167' }, 
    { position: [103.92627064723877, 30.238853469814206], unit: '168' }, 
    { position: [103.92664433952763, 30.2386476671061], unit: '169-1' }, 
    { position: [103.92649446684743, 30.238777711886684], unit: '169-2' }, 
    { position: [103.92655903651155, 30.23907292027049], unit: '170-1' }, 
    { position: [103.9267113578992, 30.23916390256198], unit: '170-2' }, 
    { position: [103.92693658626513, 30.238876262955955], unit: '171-1' }, 
    { position: [103.92678076132489, 30.23878789016226], unit: '171-2' }, 
    { position: [103.92716290355048, 30.239131138262046], unit: '172-1' }, 
    { position: [103.92705101777358, 30.238997704088455], unit: '172-2' }, 
    { position: [103.92684582949164, 30.23929432602788], unit: '173-1' }, 
    { position: [103.92696468774892, 30.239420235178965], unit: '173-2' }, 
    { position: [103.9270868364971, 30.239541861545923], unit: '174-1' }, 
    { position: [103.927216416585, 30.239671710706876], unit: '174-2' }, 
    { position: [103.9274071073923, 30.23937649830455], unit: '175-1' }, 
    { position: [103.9273151343412, 30.239227642352862], unit: '175-2' }, 
    { position: [103.92764960557463, 30.239618984972385], unit: '176-1' }, 
    { position: [103.92751387789252, 30.23949149783833], unit: '176-2' }, 
    { position: [103.92732423371223, 30.23978755134521], unit: '177-1' }, 
    { position: [103.9274413466444, 30.23991886899477], unit: '177-2' }, 
    { position: [103.9275690754805, 30.24004871020935], unit: '178-1' }, 
    { position: [103.92771048652554, 30.24014858618661], unit: '178-2' }, 
    { position: [103.92791451843732, 30.23987319616311], unit: '179-1' }, 
    { position: [103.92776680795197, 30.239742022324034], unit: '179-2' }, 
    { position: [103.92822778783494, 30.24006414234967], unit: '180-1' }, 
    { position: [103.92807045566309, 30.23997576874806], unit: '180-2' }, 
    { position: [103.927856093831, 30.24027139127352], unit: '181-1' }, 
    { position: [103.92800285094359, 30.24035668267409], unit: '181-2' }, 
    { position: [103.92820081291484, 30.240443841205465], unit: '182-1' }, 
    { position: [103.92826738467312, 30.240600357423848], unit: '182-2' }, 
    { position: [103.92851691362449, 30.240270655512017], unit: '183-1' }, 
    { position: [103.92839026562405, 30.240149291733484], unit: '183-2' }, 
    { position: [103.92864413930414, 30.240575294911118], unit: '184-1' }, 
    { position: [103.92858297974487, 30.24041969252309], unit: '184-2' }, 
    { position: [103.92833020180035, 30.24075130663035], unit: '185-1' }, 
    { position: [103.92839429946247, 30.240901626088373], unit: '185-2' }, 
    { position: [103.9284522509032, 30.24107644469931], unit: '185-3' }, 
    { position: [103.9287556917929, 30.240882477818218], unit: '186-1' }, 
    { position: [103.92869863949801, 30.240726343351817], unit: '186-2' }, 
    { position: [103.928880269258, 30.241194619935083], unit: '187-1' }, 
    { position: [103.92882001625588, 30.241040030115645], unit: '187-2' }, 
    { position: [103.9288521114043, 30.241408850943177], unit: '188' }, 
    { position: [103.92861719415396, 30.241548069866827], unit: '189' }, 
    { position: [103.92836956621835, 30.24145151990254], unit: '190' }, 
    { position: [103.92816548156264, 30.241314968953574], unit: '191' }, 
    { position: [103.9255932932046, 30.239772031824494], unit: '业主中心' }, 
    { position: [103.92556351253927, 30.239999553851945], unit: '物业中心' }, 


    // 二期
    { position: [103.92555071375898, 30.2508902310813], unit: '1栋' }, 
    { position: [103.9253674442233, 30.250342346388543], unit: '2栋' }, 
    { position: [103.92466601011006, 30.250302589052033], unit: '3栋' }, 
    { position: [103.92385525139588, 30.249983834269248], unit: '4栋' }, 
    { position: [103.92403245228354, 30.25044144342547], unit: '5栋' },
    { position: [103.92413975420214, 30.250811286091228], unit: '6栋' }, 
    { position: [103.92425463624039, 30.25121110880032], unit: '7栋' }, 
    { position: [103.92440644433634, 30.25174395371522], unit: '8栋' }, 
    { position: [103.92513719568342, 30.25162553179733], unit: '9栋' }, 
    { position: [103.92572696171788, 30.251462999032917], unit: '10栋' }, 
    { position: [103.925897791941, 30.25210332646923], unit: '14栋' },
    { position: [103.92452866421024, 30.252271646049582], unit: '15栋' },  
    { position: [103.9245146446143, 30.252972452341666], unit: '16栋' }, 
    { position: [103.92500720278747, 30.25300004134157], unit: '17栋' }, 
    { position: [103.92559550333283, 30.252987989160246], unit: '18栋' }, 
    { position: [103.92608721157586, 30.252923050757666], unit: '19栋' }, 
    { position: [103.92366916575264, 30.24862287609226], unit: '22栋' }, 
    { position: [103.92320537090211, 30.248774136512296], unit: '23栋' }, 
    { position: [103.92348689363172, 30.24937009208691], unit: '24栋' }, 
    { position: [103.92403522611664, 30.249397222588915], unit: '25栋' }, 
    { position: [103.92456607681294, 30.24920282679277], unit: '26栋' }, 
    { position: [103.92518483534485, 30.249700071159882], unit: '27栋' }, 
    { position: [103.92498119919844, 30.248923233872972], unit: '28栋' }, 
    { position: [103.92478773280766, 30.248209339036716], unit: '29栋' }, 
    { position: [103.92427340942538, 30.248354928814795], unit: '30栋' }, 


    //别墅
    { position: [103.91755057783206, 30.23502994860032], unit: '1' }, 
    { position: [103.91769231047192, 30.23538179676132], unit: '2' }, 
    { position: [103.91746642657802, 30.23565990443061], unit: '3' }, 
    { position: [103.91788829968311, 30.235960549666316], unit: '4' }, 
    { position: [103.91737852956464, 30.235848608766076], unit: '5' }, 
    { position: [103.91699858291668, 30.235555718679137], unit: '6' }, 
    { position: [103.91678920268572, 30.235913168836003], unit: '7' }, 
    { position: [103.9170180800034, 30.236117194545386], unit: '8' }, 
    { position: [103.91739281434167, 30.23613897919172], unit: '9' }, 
    { position: [103.91742737374022, 30.236337155890304], unit: '10' }, 
    { position: [103.91719691873169, 30.236534061950525], unit: '11' }, 
    { position: [103.91671536701642, 30.23658594320017], unit: '12' }, 
    { position: [103.91638900071302, 30.23633238751647], unit: '13' }, 
    { position: [103.91614282401673, 30.23666266831854], unit: '14' }, 
    { position: [103.91594771469897, 30.23697047770965], unit: '15' }, 
    { position: [103.9159314466072, 30.237312766259194], unit: '16' }, 
    { position: [103.91633184663647, 30.237268221562235], unit: '17' }, 
    { position: [103.91658697621506, 30.236882567516613], unit: '18' }, 
    { position: [103.91692648507563, 30.237080159631972], unit: '19' }, 
    { position: [103.91675639702123, 30.237364023432303], unit: '20' }, 
    { position: [103.91624462084553, 30.237571393441474], unit: '21' }, 
    { position: [103.91658123152473, 30.23771872437961], unit: '22' }, 
    { position: [103.91703086364129, 30.237989445885205], unit: '23' }, 
    { position: [103.91727788952045, 30.23787626150368], unit: '24' }, 
    { position: [103.91703124140025, 30.23766012880487], unit: '25' }, 
    { position: [103.91741884009548, 30.237670442107405], unit: '26' }, 
    { position: [103.91774766576395, 30.237988591037855], unit: '27' }, 
    { position: [103.91795889242655, 30.23840048177667], unit: '28' }, 
    { position: [103.9182651081918, 30.238531063601116], unit: '29' }, 
    { position: [103.91876991667067, 30.238383003631323], unit: '30' }, 
    { position: [103.91845255831299, 30.238240912408255], unit: '31' }, 
    { position: [103.91809865606695, 30.23807496171686], unit: '32' }, 
    { position: [103.91826982695369, 30.2377337207172], unit: '33' }, 
    { position: [103.91867052946384, 30.23786634346104], unit: '34' }, 
    { position: [103.91869932572575, 30.237583144329843], unit: '35' }, 
    { position: [103.91836519443393, 30.23743623311424], unit: '36' }, 
    { position: [103.91815907599658, 30.23743472644328], unit: '37' }, 
    { position: [103.91774457827387, 30.23735987482026], unit: '38' }, 
    { position: [103.91730539081698, 30.23709235400106], unit: '39' }, 
    { position: [103.9175057006056, 30.23670808962255], unit: '40' }, 
    { position: [103.9178255103389, 30.236823068658364], unit: '41' }, 
    { position: [103.91789561859241, 30.23652447738795], unit: '42' }, 
    { position: [103.91838092876935, 30.23670404989211], unit: '43' }, 
    { position: [103.918248028005, 30.237091724948307], unit: '44' }, 
    { position: [103.91886719617867, 30.23715122232872], unit: '45' }, 
    { position: [103.9184998362532, 30.23689886554086], unit: '46' }, 
    { position: [103.91887648514364, 30.23686771080777], unit: '47' }, 
    { position: [103.91881227915815, 30.23658362121724], unit: '48' }, 
    { position: [103.91880791430236, 30.236362810577127], unit: '49' }, 
    { position: [103.91828549432773, 30.236360326166658], unit: '50' }, 
    { position: [103.91839359674753, 30.236164444704162], unit: '51' }, 
    { position: [103.918333272246, 30.235544495689386], unit: '52' }, 
    { position: [103.91858053783773, 30.235899685881726], unit: '53' }, 
    { position: [103.91876711933833, 30.235740054553258], unit: '54' }, 
    { position: [103.91906848928124, 30.236200199870474], unit: '55' }, 
    { position: [103.91930225869794, 30.23611840351697], unit: '56' }, 
    { position: [103.91952180166476, 30.235594470922834], unit: '57' }, 
    { position: [103.91990343528647, 30.23551940144678], unit: '58' }, 
    { position: [103.91968184779171, 30.23546291534936], unit: '59' }, 
    { position: [103.91965451041926, 30.23522968928708], unit: '60' }, 
    { position: [103.9193474045764, 30.235131091791988], unit: '61' }, 
    { position: [103.9191129065245, 30.235281482813463], unit: '62' }, 
    { position: [103.91877268416135, 30.234938232167146], unit: '63' }, 
    { position: [103.91921236944694, 30.234839153245055], unit: '64' }, 
    { position: [103.91962515169865, 30.23471655524615], unit: '65' }, 
    { position: [103.92013728694616, 30.23510644914833], unit: '66' }, 
    { position: [103.92046078043836, 30.234833649044603], unit: '67' }, 
    { position: [103.92015934650391, 30.23476332561644], unit: '68' }, 
    { position: [103.92033648486358, 30.234484096513828], unit: '69' }, 
    { position: [103.91959774712535, 30.23427982969784], unit: '70' }, 
    { position: [103.91966158295224, 30.234012209665536], unit: '71' }, 
    { position: [103.9202404635397, 30.23423189048777], unit: '72' }, 
    { position: [103.92085127176739, 30.234124339538663], unit: '73' }, 
    { position: [103.92102752701953, 30.23383070269064], unit: '74' }, 
    { position: [103.92045061528125, 30.233867458317242], unit: '75' }, 
    { position: [103.92064163825484, 30.233558214453634], unit: '76' }, 
    { position: [103.92034851527046, 30.23330567001055], unit: '77' }, 
    { position: [103.91999499256235, 30.23349780624635], unit: '78' }, 
    { position: [103.91993574082721, 30.233750688870458], unit: '79' }, 
    { position: [103.91957811975021, 30.23355155716809], unit: '80' }, 
    { position: [103.91969706160404, 30.233164274893785], unit: '81' }, 
    { position: [103.92005835368786, 30.23298680969027], unit: '82' }, 
    { position: [103.91962744652852, 30.232872382738368], unit: '83' }, 
    { position: [103.91929782763532, 30.23298873762593], unit: '84' }, 
    { position: [103.9195353493489, 30.23260335769199], unit: '85' }, 
    { position: [103.91923037297056, 30.232441770511645], unit: '86' }, 
    { position: [103.91897838069116, 30.232329581282347], unit: '87' }, 
    { position: [103.91911027602129, 30.232103532484366], unit: '88' }, 
    { position: [103.918792094178, 30.232267582337055], unit: '89' }, 
    { position: [103.9187459449521, 30.232738803327443], unit: '90' }, 
    { position: [103.91854960696179, 30.23311991299969], unit: '91' }, 
    { position: [103.91852022116677, 30.233402991735186], unit: '92' }, 
    { position: [103.91887312677153, 30.2332124378271], unit: '93' }, 
    { position: [103.91910010602044, 30.233478848416972], unit: '94' }, 
    { position: [103.91884331423205, 30.23362445967845], unit: '95' }, 
    { position: [103.91874627810824, 30.23394302377182], unit: '96' }, 
    { position: [103.91914804349693, 30.2341618174901], unit: '97' }, 
    { position: [103.91886813962037, 30.234251724112603], unit: '98' }, 
    { position: [103.91838890365784, 30.234343448991982], unit: '99' }, 
    { position: [103.9179868910021, 30.23437092118757], unit: '100' }, 
    { position: [103.91886864071887, 30.238203733940164], unit: '出入口' }, 
] // { position: [], unit: '' }, 


export  const gaoceng = [
    [103.92140207761281, 30.241023987006116],
    [103.92391923070556, 30.24003090708559],
    [103.92496581741142, 30.24005584387626],
    [103.92601281741139, 30.240559185728717],
    [103.92751749572147, 30.24188780280389],
    [103.92354932325549, 30.244439149268363],
];

export const gaoceng2 = [
    [103.92406941715137, 30.253266942688203],
    [103.9237134172962, 30.25060450397998],
    [103.92266171200617, 30.24866361420883],
    [103.92502739798613, 30.247870719969317],
    [103.92646694591019, 30.253121157266854],
];

export  const wuyangxiaoqu = [
    [103.91767012062246, 30.245395403593392],
    [103.91816094771497, 30.244951063406734],
    [103.9185019236881, 30.244878160698036],
    [103.9198261910002, 30.24667465959448],
    [103.91891567716584, 30.24713445448823],
];
export  const jiangkoulaojie = [
    [103.92662576130465, 30.23373889322194],
    [103.92027318440302, 30.22643686230189],
    [103.90856894497142, 30.218175381248624],
    [103.90800082035867, 30.218707005759175],
    [103.91393631511079, 30.222912459047592],
    [103.9194879071465, 30.227039812360026],
    [103.92536144433302, 30.234156379711383],
]

export const bieshu = [
    [103.9198414451015, 30.235843649415553],
    [103.91953385198224, 30.236252491850788],
    [103.91865438636404, 30.23885947580627],//右上
    [103.91581027593753, 30.237539415258478],//左上
    [103.91567688240195, 30.2369302493032],
    [103.91643810983498, 30.23602283702479],
    [103.91727964234957, 30.234822332792465],
    [103.91771014888946, 30.234338804049266],
    [103.91830278523952, 30.233632481355617],
    [103.918437397113, 30.23228773163458],
    [103.91910887986626, 30.23193018946137],//左下
    [103.9215755364126, 30.233886891395866],//右下
]

