import React, { useEffect, useState } from "react";
import './Profile.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {postMessage} from "../internet/connectIntf"
import { useData } from "../context/context";
import Card from "../ADSpace/ADCards";
import UserInfo from "./profile/UserInfo";


const Profile: React.FC = () => {
  const [releasedMessages, setReleasedMessages] = useState<Map<number, JK.Message>>(new Map())
  const [userId, setUserId] = useState(0);
  const { user, logout, relesedAds } = useData();

  let navigate = useNavigate();


  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const userInfo = JSON.parse(storedUser ? storedUser : "") 
    const user_id = userInfo.id;
    setUserId(user_id)

    if (user_id) {
      getReleasedMessages(user_id)
    }
  }, [])



  const getReleasedMessages = async (user_id) => {
    try {
      const _user = {
        id: user_id
      }
      const response = await postMessage('get_released_messages', JSON.stringify(_user));
      const result = JSON.parse(response)
      let info: Map<number, JK.Message> = new Map();
      result.forEach(element => {
        info.set(element.id, element)
      });
      setReleasedMessages(info)
    } catch (error) {
      if (error instanceof Error) 
        console.log(error.message)
    }
  }

  const handel_logout = () => {
    logout();
    navigate('/');
  }

  const delete_message = async (message_id) => {
    if (window.confirm('确认删除广告？')) {
      try {
        const info = {
          "user_id": userId,
          "message_id": message_id
        }
        const response = await postMessage('delete_message', JSON.stringify(info))
        getReleasedMessages(userId)
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
        }
      }
    }
  }

  return (
    <div className="profile_container">
      <div className="profile_panel">
        <UserInfo />
        <button onClick={handel_logout}>退出登录</button>
      </div>
      <div className="right_panel">
        <div className="ads_panel">
          {[...relesedAds.values()].map((ad) => (
            <Card card = {ad} onClick={null}></Card>
          ))}
        </div>
        <div className="message_panel">
          {[...releasedMessages.values()].map((message) => (
            <div>
              <div className="msg_box">
                <p>{message.context}</p>
                <text className="msg_publisher">{message.publisher_name}</text>
                <span className="bi-trash msg_del" onClick={ () => delete_message(message.id)} style={{display: message.publisher_id === user?.id ? 'block' : 'none' }} ></span>
                <text className="msg_publish_time">{new Date(message.publish_time * 1000).toLocaleString()}</text>
              </div>
            </div>
          ))}
        </div>
      </div>

      <NavLink to = "/" className='back'>
        <span className='bi-arrow-left-circle-fill'></span>
      </NavLink>
    </div>
  );
};

export default Profile;