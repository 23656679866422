
import React, { useEffect, useRef, useState } from "react"
import "./Messages.css"
import { postMessage } from "../internet/connectIntf";
import { useData } from "../context/context";
import Marquee from "../component/Marquee";
import throttle from 'lodash/throttle';

const Messages: React.FC = () => {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [errorInfo, setErrorInfo] = useState("")
  const [waitingResponse, setWaitingResponse] = useState(false)
  const {user, isAuthenticated, messages, refreshMessages, loadMessages} = useData();
  const [loadTriger, setLoadTriger] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const lastScrollTop = useRef(0);

  useEffect(() => {
    refreshMessages()
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    if (loadTriger > 0) {
      loadMessages(messages.size)
    }
  }, [loadTriger])

  const handleScroll = throttle(() => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    // 判断滚动方向
    if ((scrollTop > lastScrollTop.current) 
          && ((scrollTop + windowHeight) >= (documentHeight - 300))) {
      setLoadTriger(preIndex => preIndex + 1);
    } 
  
    // 更新 lastScrollTop
    lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop; // 避免出现负值
  }, 500)

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 平滑滚动效果
    });
  };

  const handleMessageBtn = () => {
    if (isEditing) {
      leave_message()
    } else {
      setIsEditing(true)
    }
  }

  const leave_message = async () => {
    if (!textRef.current)
      return
    if (textRef.current.value.trim() === '') {
      textRef.current.value = ""
      setErrorInfo("请输入内容")
      return
    }

    setErrorInfo("")

    if (textRef.current) {
      const messageInfo = {
        publisher_id: user?.id,
        content: textRef.current.value
      }
      try {
        setWaitingResponse(true)
        const response = await postMessage('leave_message', JSON.stringify(messageInfo))
        refreshMessages();
        textRef.current.value = ''
        setIsEditing(false)
      } catch (error) {
        if (error instanceof Error) {
          setErrorInfo(error.message)
        }
      } finally {
        setWaitingResponse(false)
      }
    }
  }

  const delete_message = async (message_id) => {
    if (window.confirm('确认删除广告？')) {
      try {
        const info = {
          "user_id": user?.id,
          "message_id": message_id
        }
        const response = await postMessage('delete_message', JSON.stringify(info))
        refreshMessages();
      } catch (error) {
        if (error instanceof Error) {
          setErrorInfo(error.message)
        }
      }
    }
  }

    // 函数：将文本中的 URL 替换为 <a> 标签
    const highlightLinks = (inputText) => {
      // 正则表达式匹配URL
      const urlPattern = /(\b(https?|ftp|file):\/\/[-\w+&@#\/%?=~_|!:,.;\p{L}]*[-\w+&@#\/%=~_|])/giu;
  
      // 分割文本以处理链接和非链接部分
      const parts = inputText.split(urlPattern);

      return parts
            .filter(part => part.trim() !== 'http' && part.trim() !== 'https') 
            .map((part, index) => {
        // 如果是URL，返回<a>标签，否则返回普通文本
        if (urlPattern.test(part)) {
          return (
            <a key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        } else {
          return part; // 非链接部分返回普通文本
        }
      });
    };

  return (
    <div className="msg_container">
      <Marquee onClick={handleScrollToTop} text="公共平台请使用文明用语，每人每天只有三次留言，请勿刷屏" />
      <div className="message_input" style={{display: isAuthenticated ? 'flex' : 'none'}} >
        {isEditing && <div className="message_text">
          <textarea className="content" ref={textRef}></textarea>
          <div className='warning'>{errorInfo}</div>
        </div>}
        <div className="operater">
          <button className= {`deliver ${isEditing ? 'bi-send' : 'bi-pencil-square'}`} 
                disabled={waitingResponse} onClick={handleMessageBtn} />
          {isEditing && <button className= {`deliver bi-trash`} 
                disabled={waitingResponse} onClick={() => setIsEditing(false)} />}
        </div>
      </div>
      {[...messages.values()].map((message) => (
        <div className="msg_box">
          <p className="context">{highlightLinks(message.context)}</p>
          <text className="msg_publisher">{message.publisher_name}</text>
          <span className="bi-trash msg_del" onClick={() => delete_message(message.id)} style={{display: message.publisher_id === user?.id ? 'block' : 'none' }} ></span>
          <text className="msg_publish_time">{new Date(message.publish_time * 1000).toLocaleString()}</text>
        </div>
      ))}

    </div>
  )
}

export default Messages;