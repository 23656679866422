import "./ADSpace.css"
import ADContent from './ADContent';
import React, { useEffect, useRef, useState, CSSProperties } from 'react';
import Card from "./ADCards";
import Popup from "../component/Popup";
import ADRelease from "./ADRelease"
import { useData } from "../context/context";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import TagBox from "./TagBox";
import { postMessage } from "../internet/connectIntf";
import Marquee from "../component/Marquee";
import throttle from 'lodash/throttle';

function ADSpace() {
  const [adContentVisible, setAdContentVisible] = useState(false);
  const [releaseAdVisible, setReleaseAdVisible] = useState(false);
  const [tagBoxVisible, setTagBoxVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState<JK.CardInfo | undefined>(undefined);
  const {isAuthenticated, tags, loadAds, adList, relesedAds, refreshAds} = useData();

  const [selectedAd, setSelectedAd] = useState<JK.CardInfo>();
  const [boxStyle, setBoxStyle] = useState<CSSProperties>({});
  const [isSearching, setIsSearching] = useState(false);
  const [filteredAds, setFilteredAds] = useState<Map<number, JK.CardInfo>>(new Map);
  const [searchingTags, setSearchingTags] = useState<string []>([])
  const [closeWarning, setCloseWarning] = useState(false)
  const [loadTriger, setLoadTriger] = useState(0);

  const lastScrollTop = useRef(0);
  const searchRef = useRef<HTMLButtonElement>(null);
  const tagboxRef = useRef(null)

  const classify = [
    '全部',
    '生活',
    '装修',
    '房屋租售',
    '便民服务'
  ]
  const [selectedClassify, setSelectedClassify] = useState(0);
  const handleClassifySearch = async (index) => {
    setSelectedClassify(index)
    if (index == 0) {
      setIsSearching(false)
      return
    } 

    setIsSearching(true)

    const transfer = {
      1: 1,
      2: 10,
      3: 20,
      4: 31
    }

    const info = {
      classify: transfer[index]
    }

    try {
      const response = await postMessage('search_ads_by_classify', JSON.stringify(info))
      handleFilteredMessages(response)
    } catch (error) {
      if (error instanceof Error)
        console.log(error.message)
    }
  }

  useEffect(() => {
    if (tagBoxVisible && searchRef.current) {
      const sourceRect = searchRef.current.getBoundingClientRect()
      setBoxStyle({
        top: `${sourceRect.top + sourceRect.height}px`,
        left: `${Math.min(sourceRect.left + sourceRect.width, window.innerWidth - 280) }px`,
      });
    }
  }, [tagBoxVisible])

  useEffect(() => {
    if (adContentVisible || releaseAdVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [adContentVisible, releaseAdVisible])

  useEffect(() => {
    refreshAds()
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    if (loadTriger > 0) {
      loadAds(adList.size)
    }
  }, [loadTriger])

  const handleScroll = throttle(() => {
    setTagBoxVisible(false);
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    // 判断滚动方向
    if ((scrollTop > lastScrollTop.current) 
          && ((scrollTop + windowHeight) >= (documentHeight - 200))) {
      setLoadTriger(preIndex => preIndex + 1);
    } 
  
    // 更新 lastScrollTop
    lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop; // 避免出现负值
  }, 500);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 平滑滚动效果
    });
  };
  
  const openAdInfo = (card) => {
    getCardInfo(card.id)
  };

  const closeAdInfo = () => {
    setAdContentVisible(false);
    setSelectedCard(undefined);
  };

  const getCardInfo = async (card_id) => {
    const info = {
      ad_id: card_id
    }
    try {
      const response = await postMessage('get_ad', JSON.stringify(info))
      setSelectedCard(JSON.parse(response))
      setAdContentVisible(true);
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      }
    }
  }

  const searchAdsByTags = async (tags) => {
    const info = [...tags];
    setSearchingTags(info)
    console.log(info)
    try {
      const response = await postMessage("search_ads_by_tags", JSON.stringify(info))
      handleFilteredMessages(response)
    } catch(error) {
      if (error instanceof Error)
        console.log(error.message)
    }
    setTagBoxVisible(false)
  }

  const openAdContent = (adInfo) => {
    console.log(adInfo)
    setSelectedAd(adInfo)
    setReleaseAdVisible(true)
  }

  const closeAdContent = () => {
    setReleaseAdVisible(false) 
    setSelectedAd(undefined)
  }

  const handleFilteredMessages = (info) => {
    const adsArray = JSON.parse(info)
    const adsMap:Map<number, JK.CardInfo> = new Map();
    adsArray.forEach(element => {
      adsMap.set(element.id, element)
    });
    setFilteredAds(adsMap)
    setIsSearching(true)
  }

  const clearFilter = () => {
    setIsSearching(false);
    setFilteredAds(new Map());
    setSearchingTags([])
    if(tagboxRef.current) {
      tagboxRef.current.clearTags();
    }
  }
  
  return (
    <div className="ad-space">
      <Marquee onClick={handleScrollToTop} text="您好，这里是公开交流平台，请注意保护个人隐私，仔细甑别信息，发言保持友善，谢谢。"/>
      <section className="selection">
        {/* {searchingTags.map((tag) => (
          <div className="card-tag">{tags.get(Number(tag))?.tag}</div>
        ))}
        <span className="bi-search"
              onClick={() => setTagBoxVisible(true)} 
              ref={searchRef}></span>
        <span className="bi-trash" 
          style={{display: isSearching ? 'block' : 'none'}}
          onClick={clearFilter}></span> */}
          {
            classify.map((value, index) => (
              <div className={`classify-item ${selectedClassify === index && 'selected'}`}
                onClick={() => handleClassifySearch(index)}>{value}</div>
            ))
          }
      </section>
      <div>
        <div className="card-container">
          { isSearching ? 
            filteredAds.size > 0 ? [...filteredAds.values()].map((card, index) => (
              <Card key={index} card = {card} onClick={openAdInfo} /> )) 
                :<p>未查询到分类</p>
            : [... adList.values()].map((card, index) => (
              <Card key={index} card = {card} onClick={openAdInfo} />
            ))
           }
        </div>
        <div className="toolbar" style={{ display: isAuthenticated ? 'flex' : 'none'}} >
          {[...relesedAds.values()].map((ad) => (
            <div className="ad_item" onClick={() => { openAdContent(ad) }}>{ad.title.slice(0, 2) }</div>
          ))}
          <div className="ad_add" 
            style={{ display: relesedAds.size < 3 ? 'flex' : 'none'}} >
            <span className='bi-plus-circle-fill' onClick={ () => {openAdContent(null)} }></span>
          </div>
        </div>
      </div>
      {/* <TagBox visible={tagBoxVisible} closeBox={() => {setTagBoxVisible(false)}} 
              boxStyle = {boxStyle} clearTagRef={tagboxRef} seachByTags={searchAdsByTags}></TagBox> */}
      <Popup editable={false} showPopup={adContentVisible} handleClose={closeAdInfo} >
        <ADContent cardInfo={selectedCard}/>
      </Popup>
      <Popup editable = {closeWarning} showPopup={releaseAdVisible} handleClose={() => { setReleaseAdVisible(false) }} >
        <ADRelease preventClose = {(state) => setCloseWarning(state) } handleClose = { closeAdContent } adInfo={selectedAd} />
      </Popup>
    </div>
  )
}

export default ADSpace;