import React from 'react';
import './ErrorPage.css'
import { NavLink } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  return (
    <div className='error_page'>
      <div className='content'>
        <h1>404 - 找不到网页</h1>
        <p>您所查看的网页不存在</p>
        <NavLink className="link" to="/">返回首页</NavLink>
      </div>
    </div>
  );
};

export default ErrorPage;
