import React, { ReactNode, createContext, useState, useContext, useEffect } from "react";
import {postMessage} from "../internet/connectIntf"

interface User {
  id: number;
  name: string;
  class: number;
  token: string;
}

enum MapState {
  View,
  Mark
}

interface DataContextProps {
  user: User | null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () =>void;
  tags: Map<number, JK.tagElement>;
  adList: Map<number, JK.CardInfo>;
  relesedAds: Map<number, JK.CardInfo>;
  messages: Map<number, JK.Message>;
  refreshAds: () => void;
  refreshMessages: () => void;
  loadAds: (offset: number) => void;
  loadMessages: (offset: number) => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [tags, setTags] = useState<Map<number, JK.tagElement>>(new Map());
  const [adList, setAdList] = useState<Map<number, JK.CardInfo>>(new Map());
  const [messages, setMessages] = useState<Map<number, JK.Message>>(new Map());
  const [relesedAds, setRelesedADs] = useState<Map<number, JK.CardInfo>>(new Map());

  const per_page = 10;

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    const getTags = async () => {
      try {
        const _response = await postMessage('get_classify', '{}')
        handleTags(_response);
      } catch (error) {
        if (error instanceof Error) 
          console.log(error.message)
      }
    };

    getTags();
    refreshAds();
    refreshMessages();

    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setIsAuthenticated(true);
      getReleasedAds(JSON.parse(storedUser));
  }

  }, []);

  const getReleasedAds = async(storedUser) => {
    try {
      const _user = {
        id: storedUser.id,
      }
      const response = await postMessage('get_released_ads', JSON.stringify(_user));
      handleReleasedAds(response);
    } catch (error) {
      if (error instanceof Error) 
        console.log(error.message)
    }
  }


  const handleTags = (content: string) => {
    const classes = JSON.parse(content);
    let info: Map<number, JK.tagElement> = new Map();
    classes.forEach((element: JK.tagElement)=> {
        info.set(element.id, element)
    });
    setTags(info);
  }

  const handleAds = (ads: string) => {
    let content = JSON.parse(ads)
    const list:Map<number, JK.CardInfo> = new Map();
    content.forEach((element: JK.CardInfo) => {
      list.set(element.id, element)
    });
    setAdList(list)
  }

  const handleReleasedAds = (ads: string) => {
    let cardsInfo: Map<number, JK.CardInfo> = new Map();
    let content = JSON.parse(ads)

    const storedUser = localStorage.getItem('user');
    const _user = storedUser ? JSON.parse(storedUser) : {}

    for (const ad of content) {
      let card = ad;
      card.publisher = _user?.name ? _user?.name : ""
      cardsInfo.set(card.id, card)
    }
    setRelesedADs(cardsInfo)
  }

  const login = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
    setIsAuthenticated(true);
    getReleasedAds(user)
  };

  const logout = () => {
      localStorage.removeItem('user');
      setUser(null);
      setIsAuthenticated(false);
      setRelesedADs(new Map())
  };

  const refreshAds = async () => {
    try {
      const info = {
        per_page: per_page,
        offset:0
      }
      const response = await postMessage('get_ads', JSON.stringify(info));
      handleAds(response);
    } catch (error) {
      if (error instanceof Error) 
        console.log(error.message)
    }

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      getReleasedAds(JSON.parse(storedUser))
    }
  }

  const refreshMessages = async () => {
    try {
      const info = {
        per_page: per_page,
        offset: 0,
      }
      const response = await postMessage('get_messages', JSON.stringify(info));
      const result = JSON.parse(response)
      let temp: Map<number, JK.Message> = new Map();
      result.forEach((message: JK.Message) => {
        temp.set(message.id, message)
      }) 
      setMessages(temp)
    } catch (error) {
      if (error instanceof Error)
        console.log(error.message)
    }
  }

  const loadAds = async (offset: number) => {
    try {
      const info = {
        per_page: per_page,
        offset: offset,
      }
      const response = await postMessage('get_ads', JSON.stringify(info));
      let content = JSON.parse(response)
      setAdList(preList => {
        const newList = preList;
        content.forEach(element => {
          newList.set(element.id, element)
        });
        return newList;
      })
    } catch (error) {
      if (error instanceof Error) 
        console.log(error.message)
    }
  }

  const loadMessages = async (offset) => {
    try {
      const info = {
        per_page: per_page,
        offset: offset,
      }
      const response = await postMessage('get_messages', JSON.stringify(info));
      let content = JSON.parse(response)
      setMessages(preList => {
        const newList = preList
        content.forEach(element => {
          newList.set(element.id, element)
        });
        return newList
      })
    } catch (error) {
      if (error instanceof Error) 
        console.log(error.message)
    }
  }

  return (
    <DataContext.Provider value={{user, isAuthenticated, 
                                  login, logout, 
                                  tags, adList, relesedAds, messages, 
                                  refreshAds, refreshMessages, 
                                  loadAds, loadMessages}}>
      {children}
    </DataContext.Provider>
  );
}

export const useData = () => {
  const context = React.useContext(DataContext);
  if (context == undefined) {
    throw new Error('useData must be used within an DataProvider');
  }
  return context;
}
