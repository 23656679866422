
import React, { useEffect, useRef, useState } from "react"
import {SubmitHandler, useForm } from "react-hook-form";
import { postMessage } from '../../internet/connectIntf';
import { useData } from '../../context/context';
import { useNavigate } from 'react-router-dom';

import "./SMSLogin.css"

interface smsLoad {
  phone: string,
  code: string
}

const SMSLogin: React.FC = () => {
  const {register: sms_register, watch:sms_watch, handleSubmit: handSmsSubmit, setError: setSmsError, clearErrors: clearSmsErrors, formState: {errors: smsErrors}} = useForm<smsLoad>();
  const sms_login_phone = sms_watch("phone");
  const [counting, setCounting] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const timerRef = useRef(0)
  const { login } = useData();

  let navigate = useNavigate();

  const onSmsSubmit:SubmitHandler<smsLoad> = async (data) => {
    try {
      const response = await postMessage('login_sms',  JSON.stringify(data))
      login(JSON.parse(response))
      navigate('/');
    } catch(error) {
      if (error instanceof Error) {
        setSmsError("root", {
          message: error.message
        })
      }
    }
  }

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (isWaiting && counting > 0) {
        setCounting(preCount => preCount - 1)
      } else {
        setIsWaiting(false)
        setCounting(0)
      }
    }, 1000)

    return () => {
      clearInterval(timerRef.current)
    }

  }, [counting]);

  const startWaiting = () => {
    setCounting(60)
    setIsWaiting(true)
  }

  const handleSmsLoginAuth = async () => {
    clearSmsErrors()
    if (sms_login_phone && sms_login_phone.length == 11) {
      const info = {
        phone: sms_login_phone,
      }
      try {
        startWaiting()
        const response = await postMessage('login_sms_auth', JSON.stringify(info));
        console.log(response)
      } catch (error) {
        // endWaiting()
        if (error instanceof Error) {
          setSmsError("root", {
            message: error.message
          })
        }
      }
    } else {
      setSmsError("phone", {
        message: "请输入合法手机号"
      })
    }
  }

  return (
      <form className='form sms-login-form' onSubmit={handSmsSubmit(onSmsSubmit)}>
        <div>
          <label className='label'>+86</label>
          <input className="tel" {...sms_register("phone", {
            required: "请输入电话",
            pattern: {
              value: /^\d{11}$/,
              message: "请输入正确电话格式"
            }
          })} type="text" placeholder='请输入手机号'/>
        </div>
        {smsErrors.phone && <span className='warning'>{smsErrors.phone.message}</span>}
      <div className="auth_line">
        <input  {...sms_register("code", {
          required: "请输入验证码",
          pattern: {
            value: /^\d{6}$/,
            message: "请输入六位验证码"
          }
        })} maxLength={6} type='text' />
        <button className="code-btn" type='button' disabled={isWaiting} onClick={handleSmsLoginAuth}> { isWaiting ? counting : '发送验证码' }</button>
      </div>
      <button type='submit'>登录</button>
      {smsErrors.root && <span className='warning'>{smsErrors.root.message}</span>}
    </form>
  );
}

export default SMSLogin;