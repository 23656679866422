import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import HomePage from './HomePage'
import PhotoWall from './pages/PhotoWall';
import ADSpace from './ADSpace/ADSpace';
import Login from "./pages/Login";
import ErrorPage from "./pages/ErrorPage"
import Profile from "./pages/Profile"
import Messages from "./pages/Messages"
import { DataProvider } from './context/context';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    children: [
      {
        index: true,
        element: <Navigate to='/photos' replace = {true} />
      },
      {
        path: '/photos',
        element: <PhotoWall />
      },
      {
        path: '/ads',
        element: <ADSpace />
      },
      {
        path: '/messages',
        element: <Messages />
      },
    ]
  },
  {
    path: "*",
    element: <ErrorPage />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/profile',
    element: <Profile />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DataProvider>
      <RouterProvider router={router} />
    </DataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
