
import React, {useState} from "react";
import {SubmitHandler, useForm } from "react-hook-form";
import { postMessage } from '../../internet/connectIntf';
import { useData } from '../../context/context';
import { useNavigate } from 'react-router-dom';

interface pswLoad {
  phone: string,
  password: string
}

const PSWLogin: React.FC = () => {
  const { login } = useData();
  const [pswL, setPswL] = useState(false);
  let navigate = useNavigate();
  const {register: psw_register, handleSubmit: handlePswSubmit, setError:setPswError, formState: {errors:pswError, isSubmitting}} = useForm<pswLoad>();
  
    const onSubmit:SubmitHandler<pswLoad> = async (data) => {
      try {
        const response = await postMessage('login', JSON.stringify(data));
        login(JSON.parse(response))
        navigate('/');
      } catch (error) {
        if (error instanceof Error) {
          setPswError("root", {
            message: error.message
          });
        }
      }
    }
  
  return (
    <form className='form' onSubmit={handlePswSubmit(onSubmit)}>
    <input {...psw_register("phone", {
            required: "请输入注册手机",
            pattern: {
              value: /^\d{11}$/,
              message: "请输入正确电话格式"
            }
            })}
          type="text" placeholder="注册手机号" />
      {pswError.phone && <span className='warning'>{pswError.phone.message}</span>}
    <div>
      <input {...psw_register("password",{
        required: "请输入密码",
      })}
        type={pswL ? 'text' : 'password'} placeholder="密码" />
      {pswError.password && <span className='warning'>{pswError.password.message}</span>}
      <span className='visible_icon' onClick={()=>setPswL(!pswL)} ><i className={`bi ${pswL ? 'bi-eye' : 'bi-eye-slash'}`} /></span>
    </div>
    {/* <a className='link' onClick={() => {}}>忘记密码</a> */}
    <button type='submit'>登录</button>
    {pswError.root && <span className='warning'>{pswError.root.message}</span>}
    </form>
  );
}
export default PSWLogin;