import React, { useState } from 'react';
import './Marquee.css';

interface MarqueeProps {
  text: string;
  onClick: () => void;
}

const Marquee: React.FC<MarqueeProps> = ({ text, onClick }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible)
    return null;

  return (
    <div className="marquee" onClick={onClick}>
      <div className="marquee-content">
        {text}
      </div>
      <div className='marquee-close' onClick={() => {setIsVisible(false)}} >&times;</div>
    </div>
  );
};

export default Marquee;
