import React, { useEffect, useRef, useState } from 'react';
import { buildings, gaoceng, wuyangxiaoqu, bieshu, jiangkoulaojie, gaoceng2 } from './mapdata'
import './AMapComponent.css'

interface AMapComponentPro {
  state: JK.MapState,
}

const centerPoint = [103.91815243577537, 30.241868886172515];

const AMapComponent: React.FC<AMapComponentPro> = ( {state} ) => {
  const mapRef = useRef<any>(null); // 保存地图实例的引用
  const [markers, setMarkers] = useState<any[]>([]); // 存储所有标注点
  const [location, setLocation] = useState(false);
  var marker:any = undefined;

  const initializeAMap = () => {
    if (window.AMap) {
      const map = new window.AMap.Map('container', {
        zoom: 14.42,
        center: centerPoint,
        // features: ['bg', 'road', 'building']
        mapStyle: "amap://styles/fc43c8c7f030e6bf4a0f840b7b24a2b1",
      });
      mapRef.current = map; // 将地图实例保存到ref中

      var roadNetLayer =  new AMap.TileLayer.RoadNet({
        zooms: [16, 20],
      });
      const labelsLayer = getLableLayer();
      var plotLayer = new AMap.VectorLayer({
        zIndex: 0,
        zooms: [15.8, 20],
      });  
      map.add([labelsLayer, roadNetLayer, plotLayer]);
      addPlot(plotLayer)

      // 默认展示已有标注
      // markers.forEach((markerData) => {
      //   new window.AMap.Marker({
      //     position: markerData.position,
      //     title: markerData.title,
      //     map: map,
      //   });
      // });

      // if (isMarking) {
      //   // 在标注模式下添加点击事件
      //   map.on('click', handleMapClick);
      // } else {
      //   // 在展示模式下移除点击事件
      //   map.off('click', handleMapClick);
      // }
      map.on('click', handleMapClick);

      // map.on('zoomchange', () => {
      //   const currentZoom = map.getZoom();
      //   console.log('当前地图缩放级别:', currentZoom);
      //   // 获取当前地图的可视范围
      //   const bounds = map.getBounds();
      // });
    }
  };

  const getLableLayer = () => {
    var labelsLayer = new AMap.LabelsLayer({
      zIndex: 1000,  // 设置图层的显示层级
      zooms: [17, 20], // 设置图层适用的缩放级别范围
      collision: true, // 是否允许标注发生碰撞并自动避让
      });

    var markers = [];
    buildings.forEach((building, index) => {
      var curBulilding = {
        name: building.unit,
        position: building.position,
        opacity: 1,
        zIndex: 10,
        fold: true,
        extcData:index,
        text: {
          content: building.unit,
          direction: 'right',
          offset: [-10, 0],
          style: {
            fontSize:12,
            fillColor: '#a3abb1',
            strokeColor: '#fff',
            padding: '5,5',
            strokeWidth: 2
          }
        }
      }
      var labelMarker = new AMap.LabelMarker(curBulilding);
      markers.push(labelMarker);
    })

    labelsLayer.add(markers);
    return labelsLayer;
  }

  function addPlot(layer) {
    let polygon = new AMap.Polygon({
      path: [[gaoceng], [jiangkoulaojie], [wuyangxiaoqu], [gaoceng2], [bieshu]],
      fillColor: '#ccebc5',
      fillOpacity: 0.3,
      strokeWeight: 0,
    });
    polygon.on('click', handleMapClick);
    layer.add(polygon);
  }

  const reFocus = () => {
    if (mapRef.current) {
      const map = mapRef.current;
      map.setCenter(centerPoint);
      map.setZoom(14.42);
    }
  }

  useEffect(() => {
    if (!mapRef.current) {
      const addSecurityConfig = () => {
        const apiSecure = process.env.REACT_APP_AMAP_SECURE;
        window._AMapSecurityConfig = {
          securityJsCode: apiSecure, // 替换为你的安全密钥
        };
      };

      const loadAMapScript = () => {
        const script = document.createElement('script');
        const apiKey = process.env.REACT_APP_AMAP_KEY; // 替换为你的高德地图API密钥
        script.src = `https://webapi.amap.com/maps?v=2.0&key=${apiKey}`;
        script.async = true;
        script.onload = initializeAMap;
        document.body.appendChild(script);
      };

      addSecurityConfig();
      loadAMapScript();
    } else {
      // 如果地图已经加载过，复用它
      const map = mapRef.current;
      map.setZoom(14.42);
      map.setCenter(centerPoint);
    }
  }, []);

  const handleMapClick = (e: any) => {
    const { lnglat } = e;
    if (!mapRef.current) return;
    const map = mapRef.current;

    // console.log(`${lnglat.KL}, ${lnglat.kT}`)

    if (state == 1) { //在标记情况下
      if (!marker) {
        marker = new AMap.Marker({
          position: [lnglat.getLng(), lnglat.getLat()],
          title: `新标注 ${markers.length + 1}`,
        });
        marker.setMap(map);
      } else {
        marker.setPosition([lnglat.getLng(), lnglat.getLat()])
      }
    }

    map.setCenter([lnglat.getLng(), lnglat.getLat()]);
  };

  const getGeolocation = () => {
    if (mapRef.current) {
      const map = mapRef.current;

      AMap.plugin(["AMap.Geolocation"], function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位
          timeout: 10000,          //超过10秒后停止定位，默认：5s
          position:'RB',            //定位按钮的停靠位置
          offset: [10, 20],         //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
          zoomToAccuracy: true,     //定位成功后是否自动调整地图视野到定位点
        });

        map.addControl(geolocation);

        setLocation(true)
        geolocation.getCurrentPosition((status: string, result: any) => {
          if(status=='complete'){
            const { lng, lat } = result.position; // 获取定位坐标
            console.log(`当前位置：经度 ${lng}, 纬度 ${lat}`);
            
          }else{
            console.log(`定位失败 \n 失败原因排查信息: ${result.message} \n 浏览器返回信息： ${result.originMessage}`)
            setLocation(false)
          }
        })
      });
    }
  }

  return (
    <div className='amap'>
      
      <div id="container" style={{ 
        width: '100%', 
        height: `${state == 1 ? '300px' : '80vh'}`,
        }}>
        </div>
      <i className='map-item re-focus bi-bullseye' onClick={reFocus} ></i>
      {!location && <i className='map-item re-location bi-crosshair' onClick={getGeolocation} ></i>}
    </div>
  );
};

export default AMapComponent;
