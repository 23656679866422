import React, { useState, useEffect, useRef } from "react"
import {postMessage} from "../../internet/connectIntf"
import { useForm } from "react-hook-form";

import "./UserInfo.css"
import { useData } from "../../context/context";

interface UserInfo {
  id: number;
  name: string;
  class: number;
  password: string;
  passwordComfirm: string;
  phone: string;
  code: string;
  regiset_time: number;
}

const UserInfo: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editPsw, setEditPsw] = useState(false);
  const [counting, setCounting] = useState(0);
  const timerRef = useRef(0);

  const { user, refreshAds, login } = useData();

  const {register, formState: {errors}, setValue, setError, clearErrors, trigger, watch} = useForm<UserInfo>({
    defaultValues: {
        phone: userInfo?.phone,
        name: userInfo?.name
    }
  });
  const newName = watch("name")
  const newPhone = watch("phone")
  const newPassword = watch("password")
  const authCode = watch("code")

  useEffect(() => {
    refreshUser()
  }, [])

  useEffect(() => {
    if (userInfo) {
        setValue("name", userInfo.name)
        setValue("phone", userInfo.phone)
    }
  }, [userInfo])

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (counting > 0) {
        setCounting(preCount => preCount - 1)
      } else {
        setCounting(0)
      }
    }, 1000)

    return () => {
      clearInterval(timerRef.current)
    }

  }, [counting]);

  const refreshUser = () => {
    const storedUser = localStorage.getItem('user');
    const userInfo = JSON.parse(storedUser ? storedUser : "") 
    const user_id = userInfo.id;

    if (user_id) {
        getUserInfo(user_id)
    }
  }

  const getUserInfo = async (user_id) => {
    clearErrors()
    const request_user = {
      id: user_id
    }
    try {
      const respose = await postMessage('get_user', JSON.stringify(request_user));
      setUserInfo(JSON.parse(respose))
  } catch (err) {
      if (err instanceof Error)
        console.log(err.message)
    }
  }

  const authPhone = async () => {
    setCounting(60)
    const info = {
      phone: newPhone
    }
    try {
      const response = await postMessage("register_auth", JSON.stringify(info))
      console.log(response)
    } catch(error) {
        if (error instanceof Error) {
            setError("phone", {
                message: error.message
            })
        }
    }
  }

  const changeName = async () => {
    clearErrors()
    if (! await trigger("name"))
        return
    const info = {
      user_id: userInfo?.id,
      target: "name",
      name: newName,
    }
    console.log(info)

    try {
      const response = await postMessage("change_profile", JSON.stringify(info))
      

       
      if (user) {
        let newUserinfo = user;
        newUserinfo.name = newName;
        login(newUserinfo)
      }

      refreshAds()
      refreshUser()
      setEditName(false)
    } catch(error) {
      if (error instanceof Error) {
        setError("name", {
            message: error.message
        })
      }
    }
  }

  const changePhone = async () => {
    clearErrors()
    if (! await trigger("phone")) 
        return
    const info = {
        user_id: userInfo?.id,
        target: "phone",
        phone: newPhone,
        code: authCode
    }
    try {
      const response = await postMessage("change_profile", JSON.stringify(info))
      console.log(response)
      refreshUser()
      setEditPhone(false)
    } catch(error) {
      if (error instanceof Error) {
        setError("phone", {
            message: error.message
        })
      }
    }
  }

  const changePassword = async () => {
    clearErrors()
    if (! await trigger("password") )
        return

    if (! await trigger("passwordComfirm") )
        return

    const info = {
        user_id: userInfo?.id,
        target: "password",
        password: newPassword
    }

    try {
      const response = await postMessage("change_profile", JSON.stringify(info))
      console.log(response)
      setEditPsw(false)
    } catch(error) {
      if (error instanceof Error) {
        setError("passwordComfirm", {
            message: error.message
        })
      }
    }
  }

  const handleEditName = () => {
    clearErrors()
    setEditName(!editName)
    setEditPhone(false)
    setEditPsw(false)
  }

  const handleEditPhone = () => {
    clearErrors()
    setEditName(false)
    setEditPhone(!editPhone)
    setEditPsw(false)
  }

  const handleEditPsw = () => {
    clearErrors()
    setEditName(false)
    setEditPhone(false)
    setEditPsw(!editPsw)
  }

  const maskString = (input: string = ''): string => {
    return '*'.repeat(input.length);
  };

  return (
  <div>
    <div className="info_container">
      <div className="profile-line">
        <text className="cue-word">昵称：</text>
        {editName ? 
          <input {...register("name", {
            required: "请输入用户名",
            minLength: {
              value: 2,
              message: "至少包含2个字符",
            },
            maxLength: {
              value: 18,
              message: "最多包含18个字符"
            }
          })} type="text"/>
          : <text type="text">{userInfo?.name}</text>
        }
          <i className="modify bi-floppy" 
            style={{display: editName ? 'block' : 'none'}} 
            onClick={changeName}
            title="保存"/>
          <i className={`modify ${editName ? 'bi-reply' : 'bi-pencil'}` }
             onClick={handleEditName}
             title={editName ? "取消" : "修改"}/>
      </div>
      {errors.name && <span className="warning">{errors.name.message}</span>}
      <div className="profile-line">
        <text className="cue-word">电话：</text>
        {editPhone ?
          <input {...register("phone", {
            required: "请输入电话",
            pattern: {
              value: /^\d{11}$/,
              message: "请输入正确电话格式"
            }
          })}  type="text"/>
          : <text>{userInfo?.phone}</text>
        }
          <i className="modify bi-floppy"
             style={{display: editPhone ? 'block' : 'none'}}
             onClick={changePhone}
             title="保存"
             />
          <i className={`modify ${editPhone ? 'bi-reply' : 'bi-pencil'}` } 
            onClick={handleEditPhone}
            title={editPhone ? "取消" : "修改"} />
      </div>
      <div style={{display: editPhone ? 'flex' : 'none'}} className="auth-line">
        <input maxLength={6} {...register("code")}/>
        <button onClick={authPhone}
            disabled={counting > 0}>{counting > 0 ? counting : "发送验证码"}</button>
      </div>
      {errors.phone && <span className='warning'>{errors.phone.message}</span>}
      <div className="profile-line">
        <text className="cue-word">密码：</text>
        {editPsw?
          <input {...register("password", {
            required: "请输入密码"
          })} type="password"/>
          : <text>{maskString(userInfo?.password)}</text>
        }
          <i className="modify bi-floppy" 
            style={{display: editPsw ? 'block' : 'none'}}
            onClick={changePassword}
            title="保存"/>
          <i className={`modify ${editPsw ? 'bi-reply' : 'bi-pencil'}` }  
            onClick={handleEditPsw} 
            title={editPsw ? "取消" : "修改"}/>
      </div>
      {errors.password && <span className="warning">{errors.password.message}</span>}
      <div className="profile-line" style={{display: editPsw ? 'flex' : 'none'}}>
        <text>请重复密码</text>
        <input {...register("passwordComfirm", {
          required: "请再次输入密码",
          validate: value =>
            value === newPassword || '两次密码输入不一致'
        })} type="password"></input>
      </div>
      {errors.passwordComfirm && <span className="warning">{errors.passwordComfirm.message}</span>}
      <div className="profile-line">
        <text className="cue-word">注册时间：</text>
        <text>{new Date(userInfo?.regiset_time * 1000).toLocaleString()}</text>
      </div>
    </div>
  </div>
)}

export default UserInfo;