import React from 'react';
import './Popup.css';

function Popup({ editable, showPopup, handleClose, children }) {
  if (!showPopup) return null;

  const closeEvent = () => {
    if (window.confirm('确定离开此弹框？')) {
      handleClose();
    }
  }

  return (
    <div className="modal-overlay" onClick={editable ? closeEvent : handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={handleClose}>&times;</button>
        {React.cloneElement(children, { handleClose })}
      </div>
    </div>
  );
};

export default Popup;
