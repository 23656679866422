import React from 'react';
import { useEffect, useState } from "react";
import './ADRelease.css'
import {postMessage} from "../internet/connectIntf"
import {SubmitHandler, useForm } from "react-hook-form";
import { useData } from "../context/context";

interface ADReleaseProps {
  preventClose: (boolean) => void;
  handleClose: () => void;
  adInfo? : JK.CardInfo | null;
}

const ADRelease: React.FC<ADReleaseProps> = ( {preventClose, handleClose, adInfo} ) => {
  const [editing, setEditing] = useState(adInfo === null);
  const [fisrtList, setFirstList] = useState<number[]>([]);
  const [secondList, setSecondList] = useState<number[]>([]);
  const [secondTagValue, setSecondTagValue] = useState(0);
  const [waitingResponse, setWaitingResponse] = useState(false);
  
  const [districtIndex, setDistrictIndex] = useState(adInfo ? adInfo.district :0);
  const districts = [
    '默认',
    '未来城一期',
    '武阳镇',
    '江口老街'
  ]

  const [newTags, setNewTags] = useState(new Set(adInfo ? adInfo.tags : []));

  const adReleased = adInfo !== null;

  const {register, handleSubmit, setError, clearErrors, getValues, watch, formState: {errors}} = useForm<JK.CardInfo >({
    defaultValues: adInfo || {
      title: "",
      tags: [],
      phone: "",
      content: "",
    }
  });

  const {user, tags, refreshAds} = useData();

  useEffect(() => {
    preventClose(editing);
  }, [editing])

  useEffect(() => { // update list 
    let primTags: number[] = [];
    tags.forEach((element: JK.tagElement) => {
      if (element.level === 1) {
          primTags.push(element.id)
      }
      });
      // setFirstTagValue(0)
      setFirstList(primTags)
      let primTagId = primTags[0];
      updateSecondList(primTagId)
  }, [tags])

  const updateSecondList = (primTagId: number) => {
    let secTag: number[] = []
    for (const value of tags.values()) {
      if (value.parent_id === primTagId)
        secTag.push(value.id)
    }
    setSecondTagValue(secTag[0])
    setSecondList(secTag)
  }

  const change_classify = (event:React.ChangeEvent<HTMLSelectElement>) => {
    const primTagId: number = Number(event.target.value) 
    updateSecondList(primTagId)
  }
  const changSecondTag = (event:React.ChangeEvent<HTMLSelectElement>) => {
    const secTagId: number = Number(event.target.value) 
    setSecondTagValue(secTagId)
  }

  const add_tag = () => {
    const secondTag = tags.get(secondTagValue)
    if (secondTag) {
      setNewTags(prevTags => {
        const newTags = new Set(prevTags); 
        newTags.add(secondTag.id);
        return newTags;
    })}
  }

  const remove_tag = (tag: number) => {
    setNewTags(prevTags => {
        const newValues = new Set(prevTags); 
        newValues.delete(tag); 
        return newValues;
    })
  }

  const refreshData = async () => {
    clearErrors()
    const release_content = {
      ad_id: getValues("id"),
      user_id: user?.id
    }
    try {
      const response = await postMessage('renew_ad', JSON.stringify(release_content))
      console.log(response);
      closeEvent();
    } catch (error) {
      if (error instanceof Error) {
        setError("root", {
          message: error.message
        })
      }
    }
  }

  const onRelease:SubmitHandler<JK.CardInfo > = async (data) => {
    clearErrors()
    const release_content = {
        title: data.title,
        tags: [...newTags],
        phone: data.phone,
        publisher_id: user?.id,
        content: data.content,
        id: data.id,
        update_time: data.update_time,
        publish_time: data.publish_time,
        district: districtIndex
      }
    try {
      setWaitingResponse(true)
      if (adReleased) {
        console.log("update_ad", release_content)
        const response = await postMessage('update_ad', JSON.stringify(release_content))
        console.log(response);
      } else {
        console.log("release_ad", release_content)
        const response = await postMessage('release_ad', JSON.stringify(release_content));
        console.log(response)
      }
      closeEvent();
    } catch (error) {
      if (error instanceof Error) {
        setError("root", {
          message: error.message
        })
      }
    } finally {
      setWaitingResponse(false)
    }
  }

  const delete_ad = async () => {
    clearErrors()
    if (window.confirm('确认删除广告？')) {
      const info = {
        "user_id": user?.id,
        "ad_id": getValues("id")
      }

      try {
        const response = await postMessage("delete_ad", JSON.stringify(info))
        closeEvent()
      } catch (error) {
        if (error instanceof Error) {
          setError("root", {
            message: error.message
          })
        }
      }
    }
  }

  const closeEvent = () => {
    refreshAds()
    handleClose()
  }

  return (
    <form className="ad_release" onSubmit={handleSubmit(onRelease)}>

      {editing ? 
        <input {...register("title", {
              required: "请输入标题"
            })} maxLength={250} placeholder="请输入标题"/>
      : <h1 className='title'>{watch("title")}</h1>}
      {errors.title && <span className='warning'>{errors.title?.message}</span>}
      {editing ? 
        <input {...register("phone", {
              required: "请输入电话",
              pattern: {
                value: /^\d{11}$/,
                message: "请输入正确电话格式"
              }
          })} placeholder="联系电话"/>
      : <div>{watch("phone")}</div>}
      {errors.phone && <span className='warning'>{errors.phone?.message}</span>}
      <div className='tag-list'>
        {[...newTags].map((value) => (
          <div className="card_tag">{tags.get(Number(value))?.tag}
          <span className="remove_tag" onClick={() => {remove_tag(Number(value))}}> &times;</span>
          </div>
        ))}
      </div>
      <div className="select_area" style={{display: editing ? 'flex' : 'none'}}>
        <select onChange={change_classify}>
          {fisrtList.map((value) => (
            <option value={value}>{tags.get(value)?.tag}</option>
          ))}
        </select>
        <select style={{visibility: secondList.length === 0 ? 'hidden' : 'visible'}} onChange={changSecondTag}>
          {secondList.map((value) => (
            <option value={value}>{tags.get(value)?.tag}</option>
          ))}
        </select>
        <button type="button" onClick = {() => add_tag()}>添加标签</button>
      </div>
      {editing && <div className='district_area'>
        <div>地区：</div>
        {districts.map((name, index) => (
          <div className= {`district${districtIndex == index ? '_active' : ''}`} onClick={() => setDistrictIndex(index)}>{name}</div>
        ))}
        </div>}
      {editing ? 
        <textarea {...register("content")} className={`content`} placeholder="正文" />
        : <div className='card_content'>{watch("content")}</div>}
      <div className='button_group'>
        <button type="button" className='edit_button' onClick={() => { setEditing(true) }} style={{display: editing ? 'none' : 'block'}}>编辑</button>
        <button type="button" className='edit_button' onClick={ refreshData } style={{display: editing ? 'none' : 'block'}}>擦亮</button>
        <button type="button" className='edit_button' onClick={ delete_ad } style={{display: editing ? 'none' : 'block'}}>删除</button>
        <button type="submit" className='edit_button' disabled={waitingResponse} style={{display: editing ? 'block' : 'none'}}>发布</button>
        <button type="button" className='edit_button' onClick={ handleClose }>取消</button>
      </div>
      {errors.root && <span className='warning'>{errors.root?.message}</span>}
    </form>
  )
}

export default ADRelease;