import React, { useState } from 'react';
import "./Login.css"

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { NavLink } from 'react-router-dom';

import Tabbar from "../component/Tabbar";
import SMSLogin from "./login/SMSLogin"
import PSWLogin from './login/PSWLogin';
import Regiter from './login/Register';

function Login() {

  const [currentPage, setCurrentPage] = useState(0);

  const switchPage = () => {
    if (currentPage == 0)
      setCurrentPage(1)
    else
      setCurrentPage(0)
  }
 
  const login_tabs = [
    {title: "密码登录", type: "psw"},
    {title: "短信登录", type: "sms"},
  ]

  const [loginType, setLoginType] = useState('psw')
  const handleSwitchLoginType = (index: number) => {
    setLoginType(login_tabs[index].type)
  }

  return (
    <div className='login_view'>
      <div className='login_container'>
        <div className='page' 
            style={{transform: `translateX(${-currentPage * 100}%)`}} >
          <div >
          <Tabbar tabs = {login_tabs} onTabChange={handleSwitchLoginType}/>
          {loginType === 'psw' && (
            <PSWLogin />
          )}
          { loginType === 'sms' && (
            <SMSLogin />
          )}
          </div>
        </div>
        <div className='page' 
            style={{transform: `translateX(${(1 - currentPage) * 100}%)`}} >
              <Regiter />
        </div>
        <a className='switch' onClick={switchPage}>{ currentPage == 0 ? "立即注册" : "返回登录"}</a>
      </div>
      <NavLink to = "/" className='back'>
        <span className='bi-arrow-left-circle-fill'></span>
      </NavLink>
    </div>
  );
}

export default Login;
