import { NavLink, Outlet } from 'react-router-dom';
import './HomePage.css'
import { useData } from './context/context';

function HomePage() {
  const navItems = [
      {id: 'photos', lable: '首页', key: 1},
      {id: 'ads', lable: '广告板', key: 2},
      {id: 'messages', lable: '留言板', key: 3},
  ];
  const {user, isAuthenticated} = useData();
  return (
    <div className="Home">
      <section className="Welcome">
      </section>
      <div className='nav_bar'>
      {navItems.map((item) => (
        < NavLink 
          className={({isActive}) => {return `nav_item ${isActive ? 'active' : ''}`}} 
          key={item.id} to={`/${item.id}`} >
          {item.lable}
        </NavLink>
        ))}
      <div className='right-tab'>
        <div className='contact bi-envelope'>
          <div className='email'>意见箱：lesmo_2022@qq.com</div>
        </div>
        { isAuthenticated ? (
          <NavLink className='load' to = '/profile'>{user.name}</NavLink>
        ) : (
          <NavLink className='load' to = '/login'>登录</NavLink>
        )}
      </div>
      </div>
      <Outlet />
      <footer className="footer">
        <p className="record-number">
          备案号: <a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">蜀ICP备2024084384号-1</a>
        </p>
      </footer>
    </div>
  );
}


export default HomePage;