import React, { useEffect, useState } from 'react';
import './ADCards.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useData } from '../context/context';

const Card = ({card, onClick })=> {
  const {tags} = useData();
  const [district, setDistrict] = useState(0) ;
  const [classify, setClassify] = useState(0);
  const [isFood, setIsFood] = useState(false)

  useEffect(() => {
    if (card) {
      setDistrict(card.district)
      const _tags = card.tags;
      setClassify(0)
      setIsFood(false)
      if (_tags.length > 0) {
        const last_tag = tags.get(_tags[_tags.length - 1]);
        if (last_tag) {
          switch(last_tag.parent_id) {
            case 1: //生活
            case 10: //装修
            case 20: //房屋租售
            case 31: //便民服务
              setClassify(last_tag.parent_id)
              break
            default:
              break
          }
        }
        if (_tags.includes(2))
          setIsFood(true)

      }
    }
      
  }, [card]);

  return (
    <div className={`card ${ classify == 1 ? 'class-daily' : 
                             classify == 10 ? 'class-decorate' :
                             classify == 20 ? 'class-house' :
                             classify == 31 ? 'class-server' : ''}`} onClick={() => onClick(card)}>
      <div className={`icon-layer ${district === 1 ? 'icon-weilaicheng1' : 
                                    district === 2 ? 'icon-wuyang' : 
                                    district === 3 ? 'icon-oldstreet' : ''}`}>
        <h2 className="card-title">{card.title}</h2>
        {isFood && <div className='food-icon'>🍜</div>}
        <div className="card-tags">
          {card.tags.map(tag => (
            <span key={tag} className="card-tag">{tags.get(tag)?.tag }</span>
          ))}
        </div>
        <div className='hor_line'>
          <div className="card-author"><span><i className='bi bi-person'></i></span> {card.publisher}</div>
          <div className="card-phone"><span><i className='bi bi-phone'></i></span> {card.phone}</div>
        </div>
        {true && <p className="card-content">{card.content}</p>}
        <div className="card-date">发布时间: {new Date(card.update_time * 1000).toLocaleString()}</div>
      </div>
    </div>
  );
};

export default Card;
