import React from 'react';
import './ADContent.css';
import { useData } from '../context/context';

interface ADContentProps {
  cardInfo: JK.CardInfo ;
}

const ADContent: React.FC<ADContentProps> = ({cardInfo}) => {
  const {tags} = useData();
  if (!cardInfo) return null

  return (
    <div className='ad_content' >
      <h1 className='title'>{cardInfo.title}</h1>
      <div className="card-tags">
        {cardInfo.tags.map(tag => (
          <span key={tag} className="card-tag">{tags.get(tag)?.tag }</span>
        ))}
      </div>
      <div className = 'hor_line'>
        <div className="card-author"><span><i className='bi bi-person'></i></span> {cardInfo.publisher}</div>
        <div className="card-phone"><span><i className='bi bi-phone'></i></span> {cardInfo.phone}</div>
      </div>
      <div className='card_content'>{cardInfo.content}</div>
      <div className="card-date">发布时间: {new Date(cardInfo.update_time * 1000).toLocaleString() }</div>
    </div>
  );
};

export default ADContent;
